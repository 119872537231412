<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Consider the reaction shown below:</p>

      <p class="mb-5 pl-8">
        <chemical-latex content="2NH3(g) + 3 O2(g) + 2 CH4(g) →  2HCN(g) + 6 H2O(g)" />
      </p>

      <p class="mb-2">
        a) Using the data provided
        <a
          href="https://openstax.org/books/chemistry-2e/pages/g-standard-thermodynamic-properties-for-selected-substances"
          >here</a
        >, determine the standard enthalpy of reaction,
        <stemble-latex content="$\Delta\text{H}^\circ_\text{rxn}$" />
        for this process.
      </p>

      <calculation-input
        v-model="inputs.Hrxn"
        class="mb-5"
        prepend-text="$\Delta\text{H}^\circ_\text{rxn}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-n3">b) Is this reaction endothermic or exothermic?</p>

      <v-radio-group v-model="inputs.thermicity" :disabled="!allowEditing" class="ml-6 mb-0">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block mb-1" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>

      <p class="mb-n3">c) Is the work of this reaction positive or negative?</p>

      <v-radio-group v-model="inputs.work" :disabled="!allowEditing" class="ml-6 mb-0">
        <div v-for="option in options2" :key="option.value">
          <v-radio class="d-inline-block mb-1" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question455',
  components: {
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        Hrxn: null,
        thermicity: null,
        work: null,
      },
      options1: [
        {expression: '$\\text{Exothermic}$', value: 'exothermic'},
        {expression: '$\\text{Endothermic}$', value: 'endothermic'},
        {expression: '$\\text{More information required}$', value: 'moreInfoNeeded'},
      ],
      options2: [
        {expression: '$\\text{Positive}$', value: 'positive'},
        {expression: '$\\text{Negative}$', value: 'negative'},
        {expression: '$\\text{More information required}$', value: 'moreInfoNeeded'},
      ],
    };
  },
};
</script>
